<template>
  <div>
    <date-picker class="custom-datepicker" v-model="date" placeholder="--/--" @input="onChange" valueType="format"></date-picker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: { DatePicker },
  data() {
    return {
      date: null
    }
  },
  mounted() {
    this.date = this.params.value
  },
  methods: {
    onChange(value) {
      this.date = value
      this.params.setValue(value)
    }
  }
}
</script>

<style lang="scss">
.mx-datepicker {
  width: 100%;
  height: 100%;
  .mx-input-wrapper {
    height: 100%;
    input {
      height: 100%;
      border: none;
      border-radius: 0;
      box-shadow: none;
    }
  }
}
</style>
